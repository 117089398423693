const initialState = {

    professionalAccountData: [],
    particularAccountUsers: [],
    estateLeadData: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case "PROFESSIONAL_ACCOUNT_DETAILS":
            return { ...state, professionalAccountData: action.payload.data }
        case "PARTICULAR_ACCOUNT_DATA":
            return { ...state, particularAccountUsers: action.payload.data }
        case "REMOVE_PARTICULAR_ACCOUNT_DATA":
            return { ...state, particularAccountUsers: [],estateLeadData: [] }
        case "REMOVE_ESTATE_LEAD_DATA":
            return { ...state, estateLeadData: [] }
        case "ESTATE_LEAD_DATA":
            return { ...state, estateLeadData: action.payload && action.payload.data&&action.payload.data.estate_leads }


        default: return state
    }

}