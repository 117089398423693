import React, { Component } from 'react'
import "./loginPage.css";
import { connect } from 'react-redux';
import * as loginAction from '../../actions/index';
import 'react-toastify/dist/ReactToastify.css';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null
        }
    }
    componentDidMount() {
        let tokenfromLocal = localStorage.getItem("token")
        if (tokenfromLocal && this.props.isSignedIn) {
            this.props.history.push("/account-details")
        }

    }
    loginSubmit = (event) => {
        event.preventDefault()
        let data = {
            email: this.state.email,
            password: this.state.password
        }
        this.props.login(data)
    }
    handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({ [key]: value });
    }

    render() {
        return (

            <div className="container fit-content">
                <div className="card card-container">
                    <h2 className="hcenter"> Login As Admin  </h2>
                    <img id="profile-img" className="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="loginImg" />
                    <form className="form-signin" onSubmit={this.loginSubmit.bind(this)}>
                        <span id="reauth-email" className="reauth-email"></span>
                        <label className=" emailclass ">Email </label>
                        <input name="email" type="email" id="inputEmail" className="form-control" onChange={this.handleChange} placeholder="Email address" required autoFocus />
                        <label className="passwordclass">Password </label>

                        <input name="password" type="password" id="inputPassword" onChange={this.handleChange} className="form-control" placeholder="Password" required />

                        <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">Sign in</button>
                    </form>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loggedData: state.auth.loggedData,
        isSignedIn: state.auth.isSignedIn,
        token: state.auth.token,
        errorText: state.auth.errorText
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(loginAction.signIn(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
