import React, { Component } from 'react'
import '../professionalAccount/professionalAccount.css';
import { connect } from 'react-redux';
import CommonComponent from '../commonComponent/commonComponent';
class ProfessionalAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            file: null,
            imagePreviewUrl: "",
        }
    }


    render() {
        return (
            <CommonComponent
                openModalStateFun={this.props.openModalStateFun}
                closeModalStateFun={this.props.closeModalStateFun}

                modalState={this.props.modalState}
                accountType="PROFESSIONAL ACCOUNT" />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        dataAfterRegister: state.register.dataAfterRegister,
    }
}
export default connect(mapStateToProps, null)(ProfessionalAccount)
