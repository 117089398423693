import * as actionTypes from "../actions/actionTypes"
import history from '../history';
import API from '../apis/streams';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { headers, setAuth, headersWithBearer, successResponse, headersWithBearerNdType } from "../commonFunctions/commonFunctions"

export const signIn = (data) => (dispatch, getState) => {
    API.post('api/sessions', {
        "session": {
            "email": data.email,
            "password": data.password
        }
    }, headers).then((response) => {
        if (response.status === 200 && response.data && response.data.data && response.data.data.user_type==="admin") {
            toast("Login SuccessFull")
            setAuth(response.data && response.data.data && response.data.data.token)
            dispatch({
                type: actionTypes.SIGN_IN,
                payload: response.data.data
            })
            history.push("/account-details")
        }
        if(response.data && response.data.data && response.data.data.user_type!=="admin") {
            toast.error("You are not Authorized")
        }
    }, err => {
        if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.all[0]) {
            toast.error(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.all[0])
        }
        else {
            toast.error("SomeThing Went Wrong")
        }
    })
}



export const professionalAccountDetailsFun = (token) => async (dispatch, getState) => {
    const response = await API.get('api/professional/account', headersWithBearer(token))

    dispatch({
        type: actionTypes.PROFESSIONAL_ACCOUNT_DETAILS,
        payload: response.data
    })
}
export const imageUpload = (params, cb) => async (dispatch, getState) => {
    let token = params.token;
    delete params.token;

    API.post("/api/s3/sign/upload", params, token)
        .then(response => {
            if (response.data) {

                dispatch({
                    type: actionTypes.LOGO_IMAGE_URL,
                    payload: response.data
                })
                cb(successResponse(response, null));
            }
        })
        .catch(error => { });

};

export const particularAccountUsersFun = (params, cb) => async (dispatch, getState) => {
    API.get(`/api/professional/user/${params.id}`, headersWithBearer(params.token)).then((response) => {
        if (response.data) {
            dispatch({
                type: actionTypes.PARTICULAR_ACCOUNT_DATA,
                payload: response.data
            })
        }
        cb(successResponse(response, null));
    })
}

export const estateLeadDetailsFun = (params, cb) => async (dispatch, getState) => {

    API.get(`/api/professional/user/details/99?email=${params.email}`, headersWithBearer(params.token)).then((response) => {
        if (response.data.data) {
            dispatch({
                type: actionTypes.ESTATE_LEAD_DATA,
                payload: response.data
            })
            cb(successResponse(response, null));

        }
        if (response.data.error) {
            dispatch(nullEstateLeadData());
            toast.error("invalid Email")
        }

    })
}
export const removeParticularData = () => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.REMOVE_PARTICULAR_ACCOUNT_DATA,
    })

}
export const nullEstateLeadData = () => async (dispatch, getState) => {
    dispatch({
        type: actionTypes.REMOVE_ESTATE_LEAD_DATA,
    })
}


export const signOut = () => {
    return {
        type: actionTypes.SIGN_OUT,
    }
}
export const create = (data) => async (dispatch, getState) => {
    const response = await API.get("/posts")
    if (response) {

        history.push("/")
    }
    dispatch({
        type: actionTypes.REGISTER_ACCOUNT,
        payload: data
    })
}
export const createAccount = (data, token) => async (dispatch, getState) => {

    await API.post('api/professional/account', data, headersWithBearerNdType(token)).then((response) => {
        if (response.status === 200) {
            toast.success("Account Created")
            professionalAccountDetailsFun()
            dispatch({
                type: actionTypes.REGISTER_ACCOUNT,
                payload: response.data
            })

        }
    }).catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.email[0]) {
            toast.error(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.email[0])
        }
        else {
            toast.error("SomeThing Went Wrong")
        }
    })
}
export const registerUser = (data, token) => async (dispatch, getState) => {

    await API.post('api/users', data, headersWithBearerNdType(token)).then((response) => {
        if (response.status === 200) {
            toast.success("Created Successfully")
            dispatch({
                type: actionTypes.REGISTER_USER,
                payload: response.data
            })
        }
    }).catch((err) => {

        if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.email[0]) {
            toast.error(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.email[0])
        }
        else {
            toast.error("SomeThing Went Wrong")
        }
    })
}
export const addAnEstate = (data, token) => async (dispatch, getState) => {
    await API.post('api/estates', data, headersWithBearerNdType(token)).then((response) => {

        if (response.data && response.data.data) {
            toast.success("Added Succesfully")
            dispatch({
                type: actionTypes.ESTATE_CREATED,
            })
        }
        if (response.data && response.data.error && response.data.message) {
            toast.error(response.data.message)
            dispatch({
                type: actionTypes.ESTATE_CREATED,
            })
        }
    }).catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.user_id[0]) {
            toast.error(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.user_id[0])
        }
        else {
            toast.error("SomeThing Went Wrong")
        }
    })
}