import React, { Component } from 'react';
import "./resetPassword.css"
import { toast } from 'react-toastify';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirm_password: "",
        }
    }
    resetSubmit = (event) => {
        event.preventDefault()
        let data = {
            password: this.state.password,
            confirm_password: this.state.confirm_password
        }
        let equalCondition = data.password === data.confirm_password
        if (!equalCondition) {
            toast.error("Password and Confirm Password must be Same")
        }
        var strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        let passwordCheck = strongRegex.test(data.confirm_password)

        if (!passwordCheck) {
            toast.error("Must have atlease eight characters, one uppercase letter, one lowercase letter, one number and one special character")
        }
        else if (passwordCheck && equalCondition) {
            toast.success("Condition Succesfull")
        }
    }
    handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({ [key]: value });
    }
    render() {

        return (
            <div className="container fit-content">
                <div className="card card-container backCard">
                    <h2 className="hcenter"> Set Your Password </h2>
                    <img id="profile-img" className="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="loginImg" />
                    <form className="form-signin" onSubmit={this.resetSubmit.bind(this)}>
                        <label className="passwordclass">Password </label>

                        <input name="password" type="password" id="inputPassword" onChange={this.handleChange} className="form-control" placeholder="Password" required autoFocus />
                        <label className=" confirmpasswordclass ">Confirm Password </label>
                        <input name="confirm_password" type="password" id="inputPassword" className="form-control" onChange={this.handleChange} placeholder="Confirm Password" required />

                        <button className="btn btn-primary btn-signin button-new" type="submit">Set</button>
                    </form>
                </div>
            </div>
        )
    }
}
export default ResetPassword;