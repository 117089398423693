import React, { Component } from 'react'
import { Navbar, Nav, Form, Button } from 'react-bootstrap';
import "./navbar.css";
import { connect } from 'react-redux';
import history from "../history"
import * as loginAction from '../actions/index';
class NavBarr extends Component {
    homeClick = (e) => {
        e.preventDefault();
        history.push("/account-details")
    }
    brandClick = () => {
    }
    signInPage = () => {
        history.push("/")
    }
    componentDidMount() {
    }
    render() {
        return (
            <Navbar sticky="top" bg="dark" variant="dark">
                <Navbar.Brand onClick={this.brandClick.bind(this)} >EStateClarity</Navbar.Brand>
                <Nav className="mr-auto">
                    {!this.props.isSignedIn ? <Nav.Link onClick={this.signInPage.bind(this)}>SignIn</Nav.Link> : null}
                    {this.props.isSignedIn ? <Nav.Link onClick={this.homeClick.bind(this)}>Home</Nav.Link> : null}

                </Nav>
                <Form inline>
                    {this.props.isSignedIn && localStorage.getItem("token") ? <Button onClick={() => {
                        localStorage.removeItem("token");
                        history.push("/")
                        this.props.logout()
                    }}
                        className="btn btn-danger"
                    >
                        Logout
                    </Button> : null}
                </Form>
            </Navbar>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(loginAction.signOut()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavBarr)
