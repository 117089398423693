const initialState = {
    isSignedIn: false,
    token: null,
    loggedData: {},
    errorText: ""
}

export default (state = initialState, action) => {


    switch (action.type) {
        case "SIGN_IN":
            return { ...state, isSignedIn: true, loggedData: action.payload, token: action.payload.token }
        case "SIGN_OUT":
            return { ...state, isSignedIn: false, token: null, loggedData: [], accountDetails: [] }
        default:
            return state
    }
}