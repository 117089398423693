export const setAuth = (token) => {
    localStorage.setItem("token", token);
}
export const getAuth = () => {
    return localStorage.getItem("token");
}
export const setEmail = (email) => {
    localStorage.setItem("email", email);
}
export const getEmail = () => {
    return localStorage.getItem("email");
}
export const headers = {
    header: {
        'Content-Type': 'application/json'
    }
};

export const headersWithBearer = (token) => {
    return {
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }
};
export const headersWithBearerNdType = (token) => {
    return {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
};
export const successResponse = (result, message) => {

    let res = {
        status: true,
        message: message,
        response: result ? result : null,
        type: 'Success!'
    };
    return res;
}