import React, { Component } from 'react'
import '../professionalAccount/professionalAccount.css';
import * as registerAction from '../../actions/index';
import { connect } from 'react-redux';

class Estate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            esate_lead_id: null
        }
    }
    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    handleEstateLeadId = (e) => {
        this.setState({
            esate_lead_id: e.target.value
        })
    }
    handleEstateLeadId
    handleSubmit = (event) => {
        event.preventDefault();
        const { name, esate_lead_id } = this.state
        let token = localStorage.getItem("token")


        let data = {
            "estate": {
                "name": name,
                "esate_lead_id": esate_lead_id
            }
        }
        this.props.addAnEstate(data, token).then(() => {
            this.props.closeModalStateFun();
        })
    }

    render() {
        return (
            <div className="card-body">
                <h3 className="card-title text-center">Add Particular Estate</h3>
                <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-label-group">
                        <input type="text" id="name" className="form-control" placeholder="Name" onChange={this.handleNameChange} required />
                        <label className="labelclass" htmlFor="name">Estate Name</label>
                    </div>
                    <div className="form-label-group">
                        <input type="number" id="esate_lead_id" className="form-control" placeholder="Enter Dummy Estate" onChange={this.handleEstateLeadId} required min="0" />
                        <label className="labelclass" htmlFor="esate_lead_id">Estate Lead ID</label>
                    </div>
                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Add Estate</button>
                    <hr className="my-4" />

                </form>
            </div>

        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addAnEstate: (data, token) => dispatch(registerAction.addAnEstate(data, token)),

    }
}

export default connect(null, mapDispatchToProps)(Estate)