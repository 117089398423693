export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CREATE_STREAM = 'CREATE_STREAM';
export const FETCH_STREAM = 'FETCH_STREAM';
export const FETCH_STREAMS = 'FETCH_STREAMS';
export const DELETE_STREAM = 'DELETE_STREAM';
export const EDIT_STREAM = 'EDIT_STREAM';
export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const PROFESSIONAL_ACCOUNT_DETAILS = 'PROFESSIONAL_ACCOUNT_DETAILS';
export const ACCOUNT_MODAL_STATE = 'ACCOUNT_MODAL_STATE';
export const REGISTER_USER = 'REGISTER_USER';
export const PARTICULAR_ACCOUNT_DATA = 'PARTICULAR_ACCOUNT_DATA';
export const REMOVE_PARTICULAR_ACCOUNT_DATA = 'REMOVE_PARTICULAR_ACCOUNT_DATA';
export const LOGO_IMAGE_URL = 'LOGO_IMAGE_URL';
export const ESTATE_LEAD_DATA = 'ESTATE_LEAD_DATA';
export const REMOVE_ESTATE_LEAD_DATA = 'REMOVE_ESTATE_LEAD_DATA';
export const ESTATE_CREATED = 'ESTATE_CREATED';