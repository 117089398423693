const initialState = {
    dataAfterRegister: {},
    dataAfterRegisterUser: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "REGISTER_ACCOUNT":
            return { ...state, dataAfterRegister: action.payload }

        case "REGISTER_USER":
            return { ...state, dataAfterRegisterUser: action.payload }
        default:
            return state
    }
}