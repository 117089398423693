import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from '../history';
import ProfessionalAccount from './professionalAccount/professionalAccount';
import * as logoutAction from '../actions/index';

import ProfessionalUser from './professionalUser/professionalUser';
import EstateLead from './eStateLead/eStateLead';
import LoginPage from '../components/loginPage/loginPage';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ProfessionalAccountDetails from './professionalAccountDetails/professionalAccountDetails';
import Navbar from '../Layout/navbar';
import { connect } from 'react-redux';
import ResetPassword from './resetPassword/resetPassword'



class App extends React.Component {
  componentDidMount() {
    let token = localStorage.getItem("token")
    if (!token || !this.props.isSignedIn || this.props.token !== token) {
      this.props.logout()
      history.push("/")
    }


  }
  render() {
    return (
      <div>
        <Router history={history}>
          <Navbar />
          <div className="ui container">
            <Switch>
              <Route path="/" exact render={(props) => <LoginPage history={props.history} />} />
              <Route path="/account-details" exact render={(props) => <ProfessionalAccountDetails history={props.history} />} />
              <Route path="/professional-account" render={(props) => <ProfessionalAccount history={props.history} />} />
              <Route path="/professional-user" render={(props) => <ProfessionalUser history={props.history} />} />
              <Route path="/estate-Lead" render={(props) => <EstateLead history={props.history} />} />
              <Route path="/users/:id/reset-password/:token" render={(props) => <ResetPassword history={props.history} />} />
              <Redirect to="/" />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}
const mapStateToProps = (state) => {

  return {

    isSignedIn: state.auth.isSignedIn,
    token: state.auth.token,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction.signOut()),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)