import React, { Component } from 'react'
import '../professionalAccount/professionalAccount.css';
import * as registerAction from '../../actions/index';
import { connect } from 'react-redux';

class EstateLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            file: null,
            imagePreviewUrl: "",
            user_type: this.props.type,
            associatedId: null
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            user_type: props.type,
            associatedId: props.id
        };
    }
    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    handleFirstNameChange = (e) => {
        this.setState({
            first_name: e.target.value
        })
    }
    handleLastNameChange = (e) => {
        this.setState({
            last_name: e.target.value
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const { user_type, associatedId, email, first_name, last_name } = this.state
        let token = localStorage.getItem("token")


        let data = {
            "user": {
                "email": email,
                "user_type": user_type,
                "first_name": first_name,
                "last_name": last_name,
                "associated_id": associatedId
            }
        }

        if (this.state.user_type === "estate_lead") {
            this.props.registerEstateLead(data, token).then(() => {
                this.props.closeModalStateFun();
            })
        }
    }
    render() {
        return (
            <div className="card-body">
                <h3 className="card-title text-center">Register for <br /> EstateLead</h3>
                <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-label-group">
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" onChange={this.handleEmailChange} required />
                        <label className="labelclass" htmlFor="inputEmail">Email address</label>
                    </div>
                    <div className="form-label-group">
                        <input type="text" id="inputFirstName" className="form-control" placeholder="Name" onChange={this.handleFirstNameChange} required />
                        <label className="labelclass" htmlFor="inputFirstName">First Name</label>
                    </div>
                    <div className="form-label-group">
                        <input type="text" id="inputLastName" className="form-control" placeholder="Name" onChange={this.handleLastNameChange} required />
                        <label className="labelclass" htmlFor="inputLastName">Last Name</label>
                    </div>

                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Register</button>
                    <hr className="my-4" />

                </form>
            </div>

        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        registerEstateLead: (data, token) => dispatch(registerAction.registerUser(data, token)),

    }
}

export default connect(null, mapDispatchToProps)(EstateLead)
// export default EstateLead