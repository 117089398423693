import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from "./reducers"
import reduxThunk from "redux-thunk";
import { ToastContainer } from 'react-toastify';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import HttpsRedirect from 'react-https-redirect';


import 'react-toastify/dist/ReactToastify.css';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}
const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(reduxThunk)))
let persistor = persistStore(store)

ReactDOM.render(
    <HttpsRedirect>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastContainer />
                <App />
            </PersistGate>
        </Provider>
    </HttpsRedirect>
    , document.getElementById('root'));
serviceWorker.unregister();
