import React, { Component } from 'react'
import '../professionalAccount/professionalAccount.css';
import { connect } from 'react-redux';
import * as registerAction from '../../actions/index';
import { css } from '@emotion/core';
import { RingLoader } from 'react-spinners';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class commonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            file: null,
            imagePreviewUrl: "",
            logoUrl: "",
            loading: false
        }
    }
    onChangeFile(e) {
        let token = localStorage.getItem("token")
        let context = this;
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            let imguploadObj = {
                token: token,
                image: reader.result.split(",").pop()
            };
            context.props.imageUpload(imguploadObj, res => {


                if (res.status) {
                    context.setState({
                        logoUrl: res.response.data.data.url,
                        loading: false
                    });
                }
            });
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                user_type: this.props.userType,
                professionalUserId: this.props.professionalUserId,
                loading: true
            });
        }
        if (file) {
            reader.readAsDataURL(file)
        }
        else if (!file) {
            this.setState({ file: null })
        }
    }
    handleSubmit(event) {
        event.preventDefault();

        let data = {
            "business": {
                "email": this.state.email,
                "name": this.state.name,
                "business_logo": this.state.logoUrl
            }
        }

        let token = localStorage.getItem("token")
        this.props.register(data, token).then(() => {
            this.props.closeModalStateFun();
            this.props.professionalAccountDetailsFun(token)
        })


    }
    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    handleEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    render() {
        let { logoUrl, name, email, loading, file } = this.state;
        let $imagePreview = null;
        if (logoUrl && loading === false && file) {
            $imagePreview = (<img src={logoUrl} height="50px" width="50px" alt="imagePrieviewUrl" />);
        }
        else if (loading) {
            $imagePreview = <div className='sweet-loading'>
                <RingLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={'blue'}
                    loading={this.state.loading}
                />
            </div>
        }
        else {
            $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        }

        return (
            <div className="card-body">
                <h5 className="card-title text-center">Register for <br /> {this.props.accountType}</h5>
                <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-label-group">
                        <input type="text" id="inputText" className="form-control" placeholder="Name" autoFocus onChange={this.handleNameChange} required />
                        <label className="labelclass" htmlFor="inputText">Name</label>
                    </div>
                    <div className="form-label-group">
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email address" onChange={this.handleEmailChange} required />
                        <label className="labelclass" htmlFor="inputEmail">Email address</label>
                    </div>


                    <div className="form-label-group">
                        <input type="file" className="form-control" accept="image/*" onChange={this.onChangeFile.bind(this)} required />
                    </div>
                    <div className="imagePreview">{$imagePreview}</div>


                    <button className="btn btn-lg btn-primary btn-block text-uppercase" disabled={(!name || !email || !logoUrl) || loading ? true : false} type="submit">Register</button>
                    <hr className="my-4" />

                </form>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        register: (data, token) => dispatch(registerAction.createAccount(data, token)),
        professionalAccountDetailsFun: (token) => dispatch(registerAction.professionalAccountDetailsFun(token)),
        imageUpload: (data, cb) => dispatch(registerAction.imageUpload(data, cb)),

    }
}
const mapStateToProps = (state) => {
    return {
        dataAfterRegister: state.register.dataAfterRegister,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(commonComponent)

