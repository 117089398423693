import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import * as accountDetails from '../../actions/index';
import { Modal } from 'react-bootstrap';
import ProfessionalUser from '../professionalUser/professionalUser';
import EstateLead from '../eStateLead/eStateLead';
import Estate from '../eStateLead/estate';
import ProfessionalAccount from "../professionalAccount/professionalAccount";
import paginationFactory from 'react-bootstrap-table2-paginator';
import "./professionalAccountDetails.css";
import { css } from '@emotion/core';
import { RingLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class professionalAccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showestate: false,
            showDetails: false,
            showAddAccountModal: false,
            account_associated_id: null,
            prof_user_id: null,
            user_type: null,
            particularAccountUsers: [],
            loading: false,
            loadingEstate: false,
            professionalUserName: "",
            showEstateDetails: false,
            showAddEstateModal: false
        }
    }
    expandRow = {
        onlyOneExpanding: true,
        renderer: row => {
            const columnsForEstateDetails = [{
                dataField: 'fname',
                text: 'First Name',

            }, {
                dataField: 'lname',
                text: 'Last Name',
            }, {
                dataField: 'email',
                text: 'Email',
                classes: 'email-row'
            }
            ]
            return (
                <div>
                    <p className="estateLeadTag">{`Estate Leads for ${row.email.toUpperCase()}`} </p>
                    <BootstrapTable
                        keyField='email'
                        noDataIndication={this.emptyEstateLeads.bind(this)}
                        data={this.props && this.props.estateLeadData}
                        columns={columnsForEstateDetails}
                        striped
                        hover
                        condensed
                    />
                </div>
            )
        },
        onExpand: (row, isExpand, rowIndex, e) => {
            let token = localStorage.getItem("token")
            let data = {
                token: token,
                email: row.email
            }
            this.props.estateLeadDetailsFun(data, (res) => {
                if (res.data) {
                    this.setState({
                        loadingEstate: false
                    })
                }
            })
        }
    };
    componentDidMount() {
        let token = localStorage.getItem("token")
        if (!token || !this.props.isSignedIn) {
            this.props.history.push("/")
        }
        else if (token) {
            this.props.professionalAccountDetailsFun(token)
        }
    }
    onAddUser = (profUserId, userType, name) => {
        this.setState({
            show: true,
            account_associated_id: profUserId,
            user_type: userType,
            professionalUserName: name
        })
    }
    onAddEstateLead = (id, userType) => {
        this.setState({
            showestate: true,
            prof_user_id: id,
            user_type: userType
        })
    }
    logout = () => {
        localStorage.removeItem('token')
        this.props.logout()
    }
    showAddProfessionalAccount = () => {
        this.setState({
            showAddAccountModal: true
        })
    }
    addEstate = () => {
        this.setState({
            showAddEstateModal: true
        })
    }


    modalForUser = (cell, row, rowIndex) => {
        return (<button

            onClick={this.onAddUser.bind(this, row.id, "professional", row.name)}
            className="btn-primary"
        >
            <i className="fa fa-user-plus"></i>
        </button>)
    }


    onViewAccount = (cell, row, rowIndex) => {
        let RowId = row.id
        let token = localStorage.getItem("token")
        let data = {
            token: token,
            id: RowId
        }
        this.props.particularAccountUsersFun(data, (res) => {
            if (res) {
                this.setState({
                    loading: false
                })
            }
        })
        this.setState({
            showDetails: true,
            account_associated_id: row.id,
            loading: true
        })

    }
    modalForAccount = (cell, row, rowIndex) => {
        return (<span
            onClick={this.onViewAccount.bind(this, cell, row, rowIndex)}
            className="accountmodal"
        >
            {row.name}
        </span>)
    }
    serialNo = (cell, row, rowIndex) => {
        return cell
    }

    rowIndexSrNo = (cell, row, rowIndex) => {
        return (
            <div>
                {rowIndex + 1}
            </div>
        )
    }
    modalForEstateLead = (cell, row, rowIndex) => {

        return (<button
            type="button"
            onClick={this.onAddEstateLead.bind(this, row.id, "estate_lead")}
            className="btn-primary"
        >
            <i className="fa fa-user-plus"></i>
        </button>)
    }
    modalToViewEstateLeadFormatter = (cell, row, rowIndex) => {

        return (<button
            type="button"
            onClick={this.viewEstateLeads.bind(this, row.id)}
            className="btn-primary"
        >
            {row.email}
        </button>)
    }


    handleClose = () => {
        this.props.removeParticularData()
        this.setState({ show: false, showestate: false, showDetails: false, showAddAccountModal: false, showAddEstateModal: false, account_associated_id: null, user_type: "", professionalUserName: "" });
    };
    handleCloseEstate = () => {
        this.setState({ showestate: false, prof_user_id: null });
    }
    handleCloseEstateDetails = () => {
        this.props.nullEstateLeadData()
        this.setState({ showestate: false, showEstateDetails: false });
    }
    emptyData = () => {
        return "No Users"
    }
    emptyEstateLeads = () => {
        return "No Estate Lead"
    }


    render() {
        const rowClasses = 'custom-row-class';
        let token = localStorage.getItem("token")
        if (!token) {
            this.props.history.push("/")
        }
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };

        const columnsForDetails = [{
            dataField: 'user_id',
            text: 'User Id',
            formatter: this.serialNo.bind(this),
            headerStyle: (colum, colIndex) => {
                return { width: '100px', textAlign: 'center' };
            },

        }, {
            dataField: 'email',
            text: 'Email',
            classes: 'email-row',
        },
        {
            dataField: 'any',
            text: 'Add Estate Lead',
            formatter: this.modalForEstateLead.bind(this),
            headerStyle: (colum, colIndex) => {
                return { width: '100px', textAlign: 'center' };
            },
        }]
        const columns = [{
            dataField: 'id',
            text: 'Last Added',
            headerStyle: (colum, colIndex) => {
                return { width: '20%', wordBreak: "break-word" };
            },
            sort: true,
            formatter: this.serialNo.bind(this),

        }, {
            dataField: 'name',
            text: 'Professional Account  Name',
            headerStyle: (colum, colIndex) => {
                return { wordBreak: "break-word"};
            },
            formatter: this.modalForAccount.bind(this),
            
            classes: 'aaccount-row',
            title: true,
            sort: true
        },
        {
            dataField: 'action1',
            text: 'Add Professional User',
            formatter: this.modalForUser.bind(this),
            headerStyle: (colum, colIndex) => {
                return { wordBreak: "break-word"};
            },
        }];
        return (
            <div className="bootStrapTable">
                <div className="buttonsForModal">
                    <button onClick={this.showAddProfessionalAccount.bind(this)} className="btn btn-sm btn-info m-1" >Add Account</button>
                    <button onClick={this.addEstate.bind(this)} className="btn btn-sm btn-info m-1"> Add Estate</button>
                </div>
                <BootstrapTable
                    keyField='id'
                    data={this.props.accountDetails}
                    // data={products}
                    columns={columns}
                    striped
                    hover
                    condensed
                    pagination={paginationFactory(options)}
                    defaultSorted={defaultSorted}
                />
                {/* modal for addProfessional Account */}
                <Modal size="md" show={this.state.showAddAccountModal} onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Professional Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProfessionalAccount
                            openModalStateFun={this.showAddProfessionalAccount}
                            closeModalStateFun={this.handleClose}
                        />
                    </Modal.Body>
                </Modal>


                {/* Add Particular Estate */}

                <Modal size="md" show={this.state.showAddEstateModal} onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create an Estate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Estate
                            openModalStateFun={this.showAddProfessionalAccount}
                            closeModalStateFun={this.handleClose}
                        />
                    </Modal.Body>
                </Modal>


                {/* Modal For Professional User */}
                <Modal size="md" show={this.state.show} onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Professional User <span >({this.state.professionalUserName})</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProfessionalUser
                            closeModalStateFun={this.handleClose}
                            id={this.state.account_associated_id}
                            type={this.state.user_type}
                        />
                    </Modal.Body>
                </Modal>




                {/* Modal For Estate Lead */}

                <Modal size="md" show={this.state.showestate} onHide={() => {
                    this.setState({
                        showestate: false
                    })
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Estate Lead</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EstateLead
                            closeModalStateFun={this.handleCloseEstate}
                            id={this.state.prof_user_id}
                            type={this.state.user_type}
                        />
                    </Modal.Body>
                </Modal>



                {/* modal for account details */}
                <Modal size="lg" show={this.state.showDetails} onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Registered Users in an Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.loading ? <RingLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'blue'}
                            loading={this.state.loading}
                        /> : <BootstrapTable
                                keyField='id'
                                noDataIndication={this.emptyData.bind(this)}
                                data={this.props && this.props.particularAccountUsers}
                                columns={columnsForDetails}
                                striped
                                hover
                                condensed
                                expandRow={this.expandRow}
                                rowClasses={rowClasses}
                            />}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {

        isSignedIn: state.auth.isSignedIn,
        token: state.auth.token,
        accountDetails: state.accountDetails.professionalAccountData,
        particularAccountUsers: state.accountDetails.particularAccountUsers,
        estateLeadData: state.accountDetails.estateLeadData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(accountDetails.signOut()),
        professionalAccountDetailsFun: (token) => dispatch(accountDetails.professionalAccountDetailsFun(token)),
        particularAccountUsersFun: (data, cb) => dispatch(accountDetails.particularAccountUsersFun(data, cb)),
        removeParticularData: () => dispatch(accountDetails.removeParticularData()),
        estateLeadDetailsFun: (data, cb) => dispatch(accountDetails.estateLeadDetailsFun(data, cb)),
        nullEstateLeadData: () => dispatch(accountDetails.nullEstateLeadData()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(professionalAccountDetails)